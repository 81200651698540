import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { EffectFade, Pagination } from 'swiper'

import swiperStyles from 'swiper/swiper.scss'
import swiperFade from 'swiper/components/effect-fade/effect-fade.scss'
console.log({ swiperStyles, swiperFade })

import PlaceholderA from '../../images/full-slider-placeholder.jpg'
import PlaceholderB from '../../images/placeholder-square.jpg'
import PlaceholderC from '../../images/placeholder-wide.png'
import SectionTransition from '../../elements/activeSectionDefault'
import { getColor, breakpoint } from '../../shared/theme'

const SliderWrapper = styled(SectionTransition)`
	position: relative;

	.swiper-pagination-bullet {
		width: 12px;
		height: 12px;
		background-color: ${getColor('greyMid')};
		width: 12px;
		height: 12px;
		margin-right: 16px;
		display: inline-block;
		transform: scale(0.666);
		border-radius: 50%;
		background: ${getColor('greyMid')};
		transition: all 200ms ${(props) => props.theme.ease};

		&:last-child {
			margin-right: 0;
		}

		&.swiper-pagination-bullet-active {
			transform: scale(1);
			background: ${getColor('white')};
		}
	}

	.swiper-pagination {
		left: 20px;
		bottom: 30px;
		display: flex;
		justify-content: center;
		z-index: 10;
		position: absolute;

		@media ${breakpoint('tablet')} {
			left: 50%;
			bottom: 40px;
			transform: translateX(-50%);
		}
	}
`

const SlideInner = styled.div`
	position: relative;
	width: 100%;
	display: block;

	&::before {
		content: '';
		display: block;
		position: relative;
		padding-top: ${() => {
			return `${(320 / 370) * 100}%`
		}};

		@media ${breakpoint('tablet')} {
			padding-top: ${() => {
				return `${(700 / 1920) * 100}%`
			}};
		}
	}
`

const SwiperContainer = styled(Swiper)``

const Img = styled.img``

SwiperCore.use([EffectFade, Pagination])

const FullWidthSlider = ({ isActive, gallery }) => {
	if (!gallery || gallery.length <= 0) {
		return null
	}

	return (
		<SliderWrapper isActive={isActive}>
			<SwiperContainer
				slidesPerView={1}
				loop
				speed={1200}
				effect="fade"
				pagination={{
					clickable: true,
					el: '.swiper-pagination',
					dynamicBullets: true,
				}}
			>
				{gallery.map(function (slide, index) {
					return (
						<SwiperSlide key={index}>
							<SlideInner>
								<Img
									src={slide.mediaItemUrl}
									alt={slide.altText}
									className="bg-cover"
								/>
							</SlideInner>
						</SwiperSlide>
					)
				})}
				<div className="swiper-pagination"></div>
			</SwiperContainer>
		</SliderWrapper>
	)
}

FullWidthSlider.propTypes = {
	isActive: PropTypes.bool,
	gallery: PropTypes.array,
}

FullWidthSlider.defaultProps = {
	isActive: true,
	gallery: [],
}

export default FullWidthSlider
